import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { FaPlay, FaPause, FaRedo, FaDownload, FaShare, FaExpandArrowsAlt } from 'react-icons/fa';
import './VideoPlayer.css';
import { IoCloseCircleOutline } from 'react-icons/io5'; // Import icon
import { handleDownload, handleShare } from './VideoActions';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import MyLoading from './MyLoading';


const VideoPlayer = ({ videoId, videoUrl, videoRef, videoFileKey, setIsPopupOpen, thumbsrc, isNative, isMobile, popupMaximized, showHint, HandleMaximize, bar_images }) => {
  const STEP = 0.1;
  const MIN = 0;
  const MAX = 100;
  const [values, setValues] = useState([MIN, MAX]);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [trimStart, setTrimStart] = useState(0);
  const [trimEnd, setTrimEnd] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isAdjustingTrimEnd, setIsAdjustingTrimEnd] = useState(false);
  

  // Função para formatar o tempo no estilo MM:SS
  const formatTime = (seconds) => {
    // Garante que não existam valores negativos
    seconds = Math.max(0, seconds);

    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };


  useEffect(() => {
    //console.log("UseEffect2", {videoRef, trimEnd,isAdjustingTrimEnd})
    if (!videoRef?.current) return;
    
    const currentVideoRef = videoRef.current;

    const handleLoadedMetadata = () => {
      console.log("handleLoadedMetadata Fired")
      if (!currentVideoRef) return;
      setDuration(currentVideoRef.duration);
      setTrimEnd(currentVideoRef.duration)
      setValues([0, 100]);

    };

    const handleTimeUpdate = () => {

      console.log("handleTimeUpdate Fired")
      if (!currentVideoRef) return;
      setCurrentTime(currentVideoRef.currentTime);

      // Verifica se estamos além do trimEnd, e pausa se necessário
      if (trimEnd > 0 && currentVideoRef.currentTime >= trimEnd) {
        currentVideoRef.pause();
        setIsPlaying(false);
        currentVideoRef.currentTime = trimEnd;
        setCurrentTime(currentVideoRef.currentTime);
      }

    };


    // Adiciona os listeners do vídeo
    currentVideoRef.addEventListener('loadedmetadata', handleLoadedMetadata);

    if (!isAdjustingTrimEnd){
    currentVideoRef.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Cleanup: remove os listeners e cancela o requestAnimationFrame
    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener('loadedmetadata', handleLoadedMetadata);
        currentVideoRef.removeEventListener('timeupdate', handleTimeUpdate);
      }

    };
  }, [videoRef, trimEnd,isAdjustingTrimEnd]);


  const handleRangeChange = (newValues) => {
    // Pause the video if playing when dragging
    if (isPlaying) {
      handlePlayPause();
    }

    // Set values for the range
    if (newValues[0] <= newValues[1]) {
      setValues(newValues);
      setTrimStart((newValues[0] / 100) * duration);
      setTrimEnd((newValues[1] / 100) * duration);
    }

    // Update video current time to reflect trimStart or trimEnd
    if (videoRef?.current) {
      const newTime = (newValues[0] / 100) * duration;
      setIsAdjustingTrimEnd(true)
      // Update the video time when dragging from the start of the range
      if (!isPlaying) {
        //console.log("Not Playing")
        if (newValues[1] === values[1]) {
          //console.log("0 was equal")
          videoRef.current.currentTime = newTime;
          setCurrentTime(videoRef.current.currentTime);
        }
        // Update video time if adjusting trimEnd (i.e., dragging the right thumb)
        else if (newValues[0] === values[0]) {          
          //console.log("1 was equal")
          const newEndTime = (newValues[1] / 100) * duration;
          videoRef.current.currentTime = newEndTime;
          setCurrentTime(videoRef.current.currentTime);
        }
      }
    }
  };

  const handleThumbDragEnd = () => {

    console.log("END OF THE DRAG")
    if (videoRef?.current) {
      const newStartTime = (values[0] / 100) * duration;
      console.log("final end", values, newStartTime)

      videoRef.current.currentTime = newStartTime;
      setCurrentTime(videoRef.current.currentTime);

      setIsAdjustingTrimEnd(false) 

    }
  };

  const handleSeek = (clickPosition) => {
    if (videoRef?.current) {
      if (clickPosition < trimStart) {
        console.log("sancho1")
        videoRef.current.currentTime = trimStart;
        setCurrentTime(videoRef.current.currentTime);

      } else if (trimEnd > 0 && (clickPosition > trimEnd)) {

        console.log("sancho2")
        videoRef.current.currentTime = trimEnd;
        setCurrentTime(videoRef.current.currentTime);

      } else {
        console.log("sancho3")
        videoRef.current.currentTime = clickPosition;
        setCurrentTime(videoRef.current.currentTime);

      }
    }
  };

  const handleTrackClick = (e) => {
    if (duration > 0 && videoRef?.current) {
      console.log("sancho4")
      const rect = e.target.getBoundingClientRect();
      const clickPosition = ((e.clientX - rect.left) / rect.width) * duration;

      handleSeek(clickPosition);
    }
  };

  const handlePlayPause = () => {
    console.log("aqui001")
    if (videoRef?.current) {
      console.log("aqui002")
      if (isPlaying) {
        console.log("aqui Is Playing")
        console.log({ currentTime, trimEnd, trimStart, duration })
        videoRef.current.pause();
      } else {
        console.log("aqui Is Paused")
        console.log({ currentTime, trimEnd, trimStart, duration })
        if (((trimEnd > 0) && currentTime >= trimEnd) || currentTime < trimStart) {
          videoRef.current.currentTime = trimStart;
          setCurrentTime(videoRef.current.currentTime);

        }
        videoRef.current.play();
        setCurrentTime(videoRef.current.currentTime);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleReplay = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime = trimStart;
      setCurrentTime(videoRef.current.currentTime);

      if (!isPlaying) {
        videoRef.current.play();
        setCurrentTime(videoRef.current.currentTime);
      }
      setIsPlaying(true);
    }
  };


  const handleShareVideo = async () => {
    setIsDownloading(true); // Iniciar loading
    await handleShare(videoId, videoFileKey, trimStart, trimEnd, isMobile, isNative);
    setIsDownloading(false); // Parar loading
  };

  const handleDownloadVideo = async () => {
    setIsDownloading(true); // Iniciar loading
    await handleDownload(videoId, videoFileKey, trimStart, trimEnd, isMobile, isNative);
    setIsDownloading(false); // Parar loading
  };


  // Determina a duração para exibição: trimada ou total
  const displayDuration = trimEnd > 0 ? trimEnd - trimStart : duration;
  const displayCurrentTime = trimEnd > 0 ? currentTime - trimStart : currentTime;  
  const borderColor = (trimStart > 0 || trimEnd !== duration) ? 'orange' : '#555655';

  return (

    <div className={`video-container${popupMaximized ? '-max' : ''}`} >

      <button className="close-button-p" onClick={() => setIsPopupOpen(false)}>
        <IoCloseCircleOutline size={40} />
      </button>
      <div
        style={{
          width: '100%',
          height: '0',
          paddingBottom: '56.25%', // Aspect ratio 16:9
          position: 'relative',
          cursor: 'pointer',
          backgroundColor: '#000',
          borderRadius: '10px'
        }}
      >
        <video
          key={videoId}
          ref={videoRef}
          src={videoUrl}
          preload='auto'
          poster={thumbsrc}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            display: 'block',
            borderRadius: '10px'

          }}
          controls={false}
          playsInline={true}
        />
      </div>


      <div className={`video-info ${isNative ? ' video-info-native' : ' video-info-nonnative'}`}>
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={handleRangeChange}
          onFinalChange={handleThumbDragEnd}
          renderTrack={({ props, children }) => (
            <div
              ref={props.ref}
              style={{
                height: '48px',
                display: 'flex',
                width: '100%',
                alignSelf: 'center',
                position: 'relative'
              }}
              onClick={handleTrackClick}
            >
              {/* Retângulo de trim amarelo */}
              <div
                style={{
                  position: 'absolute',
                  //top: '-10px', // Maior no topo
                  left: `${(trimStart / duration) * 100}%`, // Posiciona baseado no trimStart
                  width: trimEnd > 0
                    ? `${((trimEnd - trimStart) / duration) * 100}%` // Calcula a largura baseada no intervalo de trim
                    : `${100}%`, // Quando trimEnd = 0, ocupa 100% menos o trimStart

                  height: '48px', // Retângulo mais alto
                  borderTop: `3px solid ${borderColor}`,
                  borderBottom: `3px solid ${borderColor}`,
                  boxSizing: 'border-box',
                  zIndex: 2, // Aparece acima do range
                  backgroundColor: 'transparent'
                }}
              >
              </div>

              {/* Renderize as imagens dentro da track */}
              <div style={{ display: 'flex', width: '100%', zIndex: 1 }}>
                {bar_images.map((bar_img, index) => (
                  <div
                    key={index}
                    style={{
                      flex: 1,
                      backgroundImage: `url(${bar_img})`,
                      backgroundSize: 'contain',
                      //backgroundRepeat: 'no-repeat', 
                      backgroundPosition: 'center',
                      height: '100%',
                    }}
                  />
                ))}
              </div>



              {/* Barra de progresso (linha amarela) */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: `${(currentTime / duration) * 100}%`,
                  height: '100%',
                  width: '2px',
                  zIndex: 9999999,
                  backgroundColor: 'yellow',
                }}
              />
              {children}
            </div>
          )}
          renderThumb={({ index, props }) => (
            <div
              key={index}
              role="slider"
              aria-valuemin={MIN}
              aria-valuemax={MAX}
              aria-valuenow={values[index]}
              {...props}
              style={{
                ...props.style,
                height: '48px',
                width: '20px',
                //borderRadius: '50%',
                backgroundColor: `${borderColor}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                left: (index === 0 ? '-10px' : '10px'),

                //boxShadow: '0px 2px 6px #AAA',
                zIndex: 2,
              }}
            >
              {index === 0 ? <FaChevronLeft /> : <FaChevronRight />}




            </div>
          )}
        />
      </div>

      <div className="video-controls">
        <div className="controls-left">
          <button onClick={handlePlayPause}>{isPlaying ? <FaPause /> : <FaPlay />}</button>
          <button onClick={handleReplay}><FaRedo /></button>
        </div>
        <div className="controls-right">


          <span>{formatTime(displayCurrentTime)} / {formatTime(displayDuration)}</span>

          {(!isNative) && (
            <button onClick={handleDownloadVideo}><FaDownload /></button>
          )}

          <button onClick={handleShareVideo}><FaShare /></button>


          {(isNative) && (
            <>

              <button disabled={popupMaximized} onClick={HandleMaximize}>



                <FaExpandArrowsAlt />
              </button>

              {showHint && (
                <div className="rotate-hint">
                  {/* Componente memoizado para o texto do hint */}
                  <p>For best viewing, rotate your device.</p>
                </div>
              )}
            </>
          )}

        </div>
      </div>


      {isDownloading && (
        <div className="downloading-popup">
          <p>Processing your video, please wait...</p>
        </div>
      )}



    </div>




  )
};


export default VideoPlayer;
